import React, { ReactElement, useMemo, useState } from 'react';

import HDivider from '@components/form/HDivider';
import { SettlementTableContainer, SettlementTitleBox } from '@components/molecules/SettlementManagment/Layout';
import { SettlementManagementListTitle } from '@components/molecules/SettlementManagment/Text';

import SelectableTable, { TTableProps } from './SelectableTable';
import { Typography } from '@mui/material';

type TSettlementListProps = TTableProps;

function SettlementList({ columns, data }: TSettlementListProps): ReactElement {
	return (
		<>
			{data && data.length > 0 && (
				<SettlementTitleBox>
					<HDivider margin="40px" type="TRANSPARENT" />
					<SettlementManagementListTitle>정산 내역</SettlementManagementListTitle>
					<Typography fontSize="16px" lineHeight="40px" my="24px">
						<span
							style={{
								margin: '0 12px 0 0'
							}}
						>
							-
						</span>
						실 입금액은 정산 금액에서{' '}
						<span
							style={{
								color: '#EFFD60'
							}}
						>
							원천세 3.3%가 차감
						</span>
						된 금액으로 입금됩니다.
					</Typography>
				</SettlementTitleBox>
			)}

			<SettlementTableContainer>
				<SelectableTable columns={columns} data={data} />
			</SettlementTableContainer>
		</>
	);
}

export default SettlementList;
