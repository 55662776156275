import React from 'react';

import Form from '@components/form/Form';
import HDivider from '@components/form/HDivider';
import Row from '@components/form/Row';
import Text from '@components/form/Text';
import VDivider from '@components/form/VDivider';
import { ReleaseAlbumButton } from '@components/molecules/AlbumManagement/Button';
import { ReleaseFirstAlbumLayout } from '@components/molecules/AlbumManagement/Layout';
import ReleaseAlbumDescBox from '@components/molecules/AlbumManagement/ReleaseAlbumDescBox';
import { AlbumReleaseDescText } from '@components/molecules/AlbumManagement/Text';
import { useModal } from '@hooks/useModal';
import { Icon } from '@images/Icon';
import history from '@utils/history';

import AlbumInfoRegistrationModal from './AlbumInfoRegistrationModal';

function ReleaseFirstAlbum() {
	const getIsDisabled = () => false;
	const { isShown: isOpenAlbumInfoRegistrationModal, toggle: setIsOpenAlbumInfoRegistrationModal } = useModal();
	const sessionUserInfo = sessionStorage.getItem('userInfo') ? JSON.parse(sessionStorage.getItem('userInfo')!) : null;

	const handleClickOpenAlbumRegistration = (e: any) => {
		e.preventDefault();

		// 멤버십이 없거나 멤버십이 있눈데 유예기간인 경우
		if (!sessionUserInfo.is_membership || (sessionUserInfo.is_membership && sessionUserInfo.is_membership_delay)) {
			history.push('/membership-info');
		} else {
			// 멤버십은 있는데 계좌인증이 안되 있는 경우
			if (!(sessionUserInfo.user_rrn && sessionUserInfo.account_number && sessionUserInfo.account_holder)) {
				history.push('/payment-completed');
			} else {
				setIsOpenAlbumInfoRegistrationModal();
			}
		}
	};

	return (
		<ReleaseFirstAlbumLayout>
			<Form style={{ width: '650px', alignItems: 'center' }}>
				<Text textType="TITLE">첫 앨범 발매하기</Text>
				<HDivider margin="28px" type="TRANSPARENT" />
				<AlbumReleaseDescText style={{ marginBottom: '15px' }}>
					유통수수료 없이 글로벌 플랫폼에 당신의 첫 앨범을 발매해 보세요.
				</AlbumReleaseDescText>
				<AlbumReleaseDescText>앨범을 발매하기 위해서는 아래 자료가 반드시 필요합니다.</AlbumReleaseDescText>
				<HDivider margin="30px" type="TRANSPARENT" />
				<Row direction="row" justify="SPACE_BETWEEN">
					<ReleaseAlbumDescBox>
						<Icon icon="albumCoverIcon" style={{ width: '80px', height: '80px' }} />
						<HDivider margin="29px" type="TRANSPARENT" />
						<Text style={{ color: '#effd60', fontSize: '14px', fontWeight: '400' }} textType="DESCRIPTION">
							앨범커버
						</Text>
						<HDivider margin="8px" type="TRANSPARENT" />
						<Text style={{ color: '#e8e8e8', fontSize: '16px', fontWeight: '400' }} textType="DESCRIPTION">
							3,000 X 3,000 px 이상의
						</Text>
						<Text style={{ color: '#e8e8e8', fontSize: '16px', fontWeight: '400' }} textType="DESCRIPTION">
							정방형 JPG 파일
						</Text>
					</ReleaseAlbumDescBox>
					<VDivider margin="40px" type="TRANSPARENT" />
					<ReleaseAlbumDescBox>
						<Icon icon="musicIcon" style={{ width: '80px', height: '80px' }} />
						<HDivider margin="29px" type="TRANSPARENT" />
						<Text style={{ color: '#effd60', fontSize: '14px', fontWeight: '400' }} textType="DESCRIPTION">
							음원
						</Text>
						<HDivider margin="8px" type="TRANSPARENT" />
						<Text style={{ color: '#e8e8e8', fontSize: '16px', fontWeight: '400' }} textType="DESCRIPTION">
							44.1kHz/16bit 이상의
						</Text>
						<Text style={{ color: '#e8e8e8', fontSize: '16px', fontWeight: '400' }} textType="DESCRIPTION">
							고음질 WAV 파일
						</Text>
					</ReleaseAlbumDescBox>
				</Row>
				<HDivider margin="30px" type="TRANSPARENT" />

				<ReleaseAlbumButton disabled={getIsDisabled()} onClick={handleClickOpenAlbumRegistration}>
					앨범발매
				</ReleaseAlbumButton>
			</Form>
			<AlbumInfoRegistrationModal
				isOpenAlbumInfoRegistrationModal={isOpenAlbumInfoRegistrationModal}
				setIsOpenAlbumInfoRegistrationModal={setIsOpenAlbumInfoRegistrationModal}
			/>
		</ReleaseFirstAlbumLayout>
	);
}

export default ReleaseFirstAlbum;
